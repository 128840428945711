/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 1rem;
    color: #fff;
}

.nav-brand {
    font-size: 1.5rem;
    font-weight: bold;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
}

.nav-buttons button {
    margin-left: 1rem;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
}

.show {
    display: flex;
    flex-direction: column;
}

/* Media query for small screens (e.g., mobile devices) */
@media screen and (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .nav-buttons {
        display: none;
    }

    .show {
        display: flex;
        flex-direction: column;
    }


    .hamburger {
        display: flex;
    }
}