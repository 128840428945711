.map-container {
    position: relative;
    background-image: url('../../assets/plano.png');
    /* Replace with your map image */
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll; /* Allow vertical scrolling if necessary */
    overflow-x: hidden; /* Disable horizontal scrolling */
    /* Adjust this to the desired size */
}

.stage-marker {
    position: absolute;
    transform: translate(-50%, -50%);
    /* Center the marker */
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@keyframes blinkBackgroundGreen {

    0%,
    100% {
        background-color: green;
    }

    50% {
        background-color: transparent;
    }
}

@keyframes blinkBackgroundRed {

    0%,
    100% {
        background-color: red;
    }

    50% {
        background-color: transparent;
    }
}

/* Blinking background effect for under 1 minute */
.blink-background-green {
    animation: blinkBackgroundGreen 1s step-start infinite;
}

/* Blinking background effect for over 1 minute */
.blink-background-red {
    animation: blinkBackgroundRed 1s step-start infinite;
}

/* Ensure the font is always black */
tr {
    color: black;
}