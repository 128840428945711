/* General Container */
.work-orders-container {
    display: flex;
    flex-direction: column; /* Default is column for mobile screens */
    gap: 20px;
    padding: 10px;
}

/* Grid layout for larger screens */
@media (min-width: 1024px) {
    .work-orders-container {
        flex-direction: row; /* Change to row for larger screens */
        justify-content: space-between;
    }

    .work-orders-list {
        flex: 1; /* Make each list take equal space */
        margin-right: 10px; /* Add some space between lists */
    }

    .planned-list {
        margin-right: 0; /* No margin on the last column */
    }
}

/* Work Orders List */
.work-orders-list {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
}

/* Headings */
h2.planned-title,
h2.unplanned-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

/* Calendar Container */
.calendar-container {
    margin-bottom: 20px;
}

/* Table */
.work-order-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.work-order-table th, 
.work-order-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 1em;
}

/* Make the table scrollable for small screens */
@media (max-width: 768px) {
    .work-order-table {
        display: block;
        overflow-x: auto; /* Horizontal scrolling */
        white-space: nowrap;
    }
}

/* Adjust row styles for small screens */
@media (max-width: 480px) {
    .work-order-table th, 
    .work-order-table td {
        padding: 8px;
        font-size: 0.9em;
    }

    .work-orders-list {
        padding: 10px;
    }

    h2.planned-title, 
    h2.unplanned-title {
        font-size: 1.3em;
    }

    .calendar-container {
        margin-bottom: 15px;
    }
}

/* Additional spacing for mobile */
@media (max-width: 320px) {
    h2.planned-title, 
    h2.unplanned-title {
        font-size: 1.1em;
    }
    
    .work-order-table th, 
    .work-order-table td {
        font-size: 0.8em;
        padding: 5px;
    }
}