/* Base styles for table container */
.table-container {
    padding: 10px;
    margin: 0 auto;
    max-width: 100%;
}

/* Responsive Date Picker Container */
.datetime-picker-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.datetime-picker-container label {
    font-size: 14px;
    margin-bottom: 5px;
}

.datetime-picker-container input {
    padding: 5px;
    font-size: 14px;
}

/* Scrollable table for smaller screens */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 14px;
}

table thead th {
    background-color: #f4f4f4;
    padding: 8px;
    text-align: left;
    font-size: 14px;
}

table tbody td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

table tbody .bold-row td {
    font-weight: bold;
    background-color: #e0e0e0;
}

/* Horizontal scrolling for smaller screens */
@media (max-width: 600px) {
    .table-container {
        overflow-x: auto;
    }

    table {
        display: block;
        width: auto;
        min-width: 600px; /* Ensures table doesn't shrink too much */
    }

    .datetime-picker-container {
        flex-direction: column; /* Stack date pickers vertically on mobile */
    }

    .datetime-picker-container label {
        font-size: 12px;
    }

    .datetime-picker-container input {
        font-size: 12px;
    }

    table thead th,
    table tbody td {
        font-size: 12px;
        padding: 6px;
    }
}